import React from 'react'
import {ButtonPriority, Dialog, Text, TextTypography, Button} from 'wix-ui-tpa'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {usePageActions, usePageStateSelector} from '../../../hooks/state'
import {getModalPayload} from '../../../selectors/modals'
import {NativeModal} from '../constants'
import {getHomePageUrl} from '../../../../../../commons/selectors/environment'
import {classes} from './headless-error.st.css'

export const HeadlessError = () => {
  const {t} = useTranslation()
  const {closeNativeModal, navigateToHomePage} = usePageActions()
  const {message} = usePageStateSelector(state => getModalPayload<NativeModal.HEADLESS_ERROR>(state))
  const homePageUrl = usePageStateSelector(getHomePageUrl)

  return (
    <Dialog isOpen={true} onClose={() => closeNativeModal()}>
      <div className={classes.container}>
        <Text typography={TextTypography.runningText} className={classes.title}>
          {message}
        </Text>
        {homePageUrl ? (
          <div className={classes.buttonContainer}>
            <Button
              upgrade={true}
              priority={ButtonPriority.primary}
              className={classes.button}
              onClick={() => navigateToHomePage()}
            >
              {t('backToSite')}
            </Button>
          </div>
        ) : null}
      </div>
    </Dialog>
  )
}
