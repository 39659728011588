import classNames from 'classnames'
import React from 'react'
import cl from '../classnames.scss'
import s from './subtitle.scss'
import {SubtitleProps} from './index'

export const Subtitle: React.FC<SubtitleProps> = ({dataHook, className, text, mobile, customColor, compact}) => (
  <h2
    className={classNames(
      cl.evSubtitlesFont,
      customColor || cl.evSubtitlesColor,
      mobile ? s.mobile : s.desktop,
      compact ? s.compact : '',
      className,
    )}
    data-hook={dataHook}
  >
    {text}
  </h2>
)
