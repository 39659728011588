import classNames from 'classnames'
import React from 'react'
import {
  saleEndedAndHasTickets,
  saleScheduledAndHasTickets,
  saleStartedAndHasTickets,
  shouldShowSaleEndsLabel,
  hasPricingOptions,
} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Badge} from '../../../badge'
import c from '../../../classnames.scss'
import {QuantityPicker} from '../parts/quantity-picker'
import {SaleEndsLabel} from '../parts/labels/sale-ends-label'
import {GoesOnSaleLabel} from '../parts/labels/goes-on-sale-label'
import {LimitReachedTooltip} from '../parts/limit-reached-tooltip'
import {classes, st} from './mobile-ticket.st.css'
import {Price} from './columns/price'
import {TicketDescription} from './columns/ticket-name-column/ticket-description'
import {MobilePricingOptions} from './mobile-pricing-options'
import {TicketProps, PickerProps} from './index'

export const Ticket = ({
  ticket,
  selectedQuantity,
  availableTicketsQuantity,
  registrationClosed,
  onChange,
  withSeatingPlan,
  isEventEnded,
  updatedInterface,
}: TicketProps) => {
  const {t} = useTranslation()
  const {id, limitPerCheckout, name, description} = ticket
  const pricingOptionsTicket = hasPricingOptions(ticket)
  const pricingOptionsReadonly = !saleStartedAndHasTickets(ticket) || registrationClosed || withSeatingPlan

  return (
    <div key={id} className={classNames(classes.ticket, c.evTextColor, c.evTextFont)} data-hook="ticket">
      {!limitPerCheckout ? <SoldOut /> : null}
      {saleEndedAndHasTickets(ticket) && <SaleEnded />}
      <div
        className={classNames(
          st(classes.name, {pricingOptions: pricingOptionsTicket}),
          c.evTicketNameColor,
          c.evTicketNameFont,
          {[classes.updatedInterface]: updatedInterface},
        )}
        data-hook="ticket-name"
        aria-label={`${t('ticketsThankYou.ticketName')}: ${name}`}
      >
        {name}
      </div>
      <Price className={classes.price} ticket={ticket} />
      {shouldShowSaleEndsLabel(ticket) && (
        <SaleEndsLabel endDate={ticket.salePeriod.formattedSaleEndDate} className={classes.saleEndsContainer} />
      )}
      {saleStartedAndHasTickets(ticket) && !pricingOptionsTicket ? (
        <Picker
          id={id}
          ticket={ticket}
          selectedQuantity={selectedQuantity}
          limitPerCheckout={limitPerCheckout}
          availableTicketsQuantity={availableTicketsQuantity}
          registrationClosed={registrationClosed}
          withSeatingPlan={withSeatingPlan}
          onChange={onChange}
        />
      ) : null}
      {saleScheduledAndHasTickets(ticket) &&
        (registrationClosed && isEventEnded ? (
          <SaleEnded />
        ) : (
          <GoesOnSaleLabel
            startDate={ticket.salePeriod.formattedSaleStartDate}
            className={classes.goesOnSaleContainer}
          />
        ))}
      {description ? (
        <div className={classes.description}>
          <TicketDescription ticketDefId={id} description={description} />
        </div>
      ) : null}
      {pricingOptionsTicket ? <MobilePricingOptions ticket={ticket} readonly={pricingOptionsReadonly} /> : null}
      <div className={classNames(c.evTicketDividerColor, classes.mobileCheckoutDivider)} />
    </div>
  )
}

const Picker = ({
  id,
  ticket,
  selectedQuantity,
  limitPerCheckout,
  availableTicketsQuantity,
  registrationClosed,
  withSeatingPlan,
  onChange,
}: PickerProps) => {
  const {t} = useTranslation()

  if (withSeatingPlan && !registrationClosed) {
    return <div>{t('ticketsPicker_onSale')}</div>
  }

  return (
    <LimitReachedTooltip ticket={ticket}>
      <div className={classes.quantity}>
        <QuantityPicker
          quantity={selectedQuantity}
          min={0}
          max={Math.min(availableTicketsQuantity + selectedQuantity, limitPerCheckout)}
          disabled={!limitPerCheckout || registrationClosed}
          onChange={value => onChange({ticketId: id, count: value})}
        />
      </div>
    </LimitReachedTooltip>
  )
}

const SoldOut = () => {
  const {t} = useTranslation()

  return (
    <div className={classes.soldOut}>
      <Badge>{t('mobile.ticketsPicker.soldOut')}</Badge>
    </div>
  )
}

const SaleEnded = () => {
  const {t} = useTranslation()

  return (
    <div className={classes.saleEnded}>
      <Badge>{t('ticketsPicker.saleEndedBadge')}</Badge>
    </div>
  )
}
