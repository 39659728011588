import {getFormattedLocation} from '@wix/wix-events-commons-statics'
import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEventDateInformation} from '../../../../../../../commons/hooks/dates'
import cl from '../../../classnames.scss'
import {ShortDateLocationProps} from './interfaces'
import s from './short-date-location.scss'

const separator = '  |  '

export const ShortDateLocation = ({visible, event, mobile, updatedMobileInterface}: ShortDateLocationProps) => {
  const {shortStartDate} = useEventDateInformation(event.id)
  if (!visible && !mobile) {
    return null
  }

  return (
    <div
      className={classNames(s.container, cl.evShortDateLocationFont, cl.evShortDateLocationColor, {
        [s.mobile]: mobile,
        [s.updatedMobileInterface]: updatedMobileInterface,
      })}
      data-hook={DH.headerShortDateLocation}
      role="text"
    >
      <p className={s.date} data-hook={DH.headerShortDate}>
        {shortStartDate}
      </p>
      <span className={s.separator}>{separator}</span>
      <p className={s.location} data-hook={DH.headerShortLocation}>
        {getFormattedLocation(event)}
      </p>
    </div>
  )
}
