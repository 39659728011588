import {BI_ORIGINS} from '@wix/wix-events-commons-statics'
import {focusElement, hookToAttributeSelector, scrollElementIntoView} from '@wix/panda-js-utils'
import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../classnames.scss'
import {AboutSection} from '../event-details/about-section'
import {getAlignmentClass} from '../event-details/alignment'
import {FullDate} from '../event-details/full-date'
import {FullLocation} from '../event-details/full-location'
import {Map} from '../event-details/map'
import {RecurringDatesButton} from '../event-details/recurring-dates-button'
import {RecurringDatesCount} from '../event-details/recurring-dates-count'
import {SecondaryRsvpButton} from '../event-details/secondary-rsvp-button'
import {Members} from '../members'
import {SocialShareWithTitle} from '../social-share/with-title'
import {Subtitle} from '../subtitle'
import {RegistrationButton} from '../event-details/registration-button'
import {GroupActivity} from '../event-details/group-activity'
import {MobileTicketsPicker} from '../checkout/mobile-checkout/mobile-tickets-picker'
import {useSettings} from '../../../hooks/settings'
import {settingsParams} from '../../../settingsParams'
import {ShortDateLocation} from '../event-details/header/short-date-location'
import {MobileSchedule} from './mobile-schedule'
import s from './event-details-mobile.scss'
import {MobileImage} from './mobile-image'
import {Description} from './description'
import {EventDetailsProps, ContainerProps} from './index'

export const EventDetailsMobileNew = ({
  componentSettings,
  eventTitle,
  headerAlignment,
  contentAlignment,
  membersVisible,
  t,
  mapVisible,
  aboutSectionVisible,
  socialShareVisible,
  scheduleVisible,
  sideBySideHeaderLayout,
  descriptionVisible,
  detailsPageLoaded,
  handleRSVP,
  mainImage,
  eventDescription,
  moreDatesVisible,
  moreDatesButtonVisible,
  groupVisible,
  hasTickets,
  ticketedEvent,
  withSeatingPlan,
  openSeatingPlan,
  shortDateLocationVisible,
}: EventDetailsProps) => {
  React.useEffect(() => {
    detailsPageLoaded()
    focusElement({selector: hookToAttributeSelector(DH.eventDetails)})
  }, [])

  const isImageVisible = () => Boolean(mainImage)

  const handleRegistrationButtonClick = () => {
    if (ticketedEvent) {
      if (withSeatingPlan) {
        return openSeatingPlan()
      } else {
        return scrollToTicketsPicker()
      }
    } else {
      return handleRSVP()
    }
  }

  const settings = useSettings(componentSettings)
  const timeAndLocationTitleText = settings.get(settingsParams.timeAndLocationTitleText)
  const guestsTitleText = settings.get(settingsParams.guestsTitleText)
  const aboutTitleText = settings.get(settingsParams.aboutTitleText)
  const readMoreText = settings.get(settingsParams.readMoreText)
  const readLessText = settings.get(settingsParams.readLessText)
  const scheduleTitleText = settings.get(settingsParams.scheduleTitleText)

  return (
    <section data-hook={DH.eventDetails} className={s.view} aria-label={eventTitle}>
      <div className={classNames(s.background, c.evBackgroundColor)} />
      <div className={classNames({[s.borderWrapper]: sideBySideHeaderLayout})}>
        {isImageVisible() ? <MobileImage /> : null}
        <div className={s.header}>
          <div className={s.headerBackground} />
          <div className={s.content}>
            <div className={getAlignmentClass(headerAlignment)}>
              {shortDateLocationVisible ? <ShortDateLocation mobile updatedMobileInterface /> : null}
              <h1 className={classNames(s.title, c.evTitleFont, c.evTitleColor)} data-hook={DH.headerEventTitle}>
                {eventTitle}
              </h1>
              {descriptionVisible ? <Description description={eventDescription} /> : null}
            </div>
          </div>
          <RegistrationButton
            className={s.registrationButton}
            onClick={handleRegistrationButtonClick}
            disableSpacing
            narrowerButton
          />
        </div>
      </div>
      <Container visible contentAlignment={contentAlignment} dataHook="dateLocationContainer">
        <Subtitle
          className={s.eventInfoTitle}
          dataHook={DH.subtitleTimeAndLocation}
          text={timeAndLocationTitleText}
          customColor={c.evDescriptionColor}
        />
        <div className={classNames(s.timeAndLocation, c.evTextFont, c.evDescriptionColor)}>
          <FullDate />
          <FullLocation />
        </div>
        {moreDatesVisible && <RecurringDatesCount className={s.recurrentDatesCount} disableSpacing />}
      </Container>
      <Container
        visible={moreDatesButtonVisible}
        contentAlignment={contentAlignment}
        dataHook="recurringDatesButtonContainer"
      >
        <RecurringDatesButton className={s.recurrentDatesButton} narrowerButton />
      </Container>
      <Container visible={membersVisible} contentAlignment={contentAlignment} dataHook="membersContainer">
        <Subtitle
          className={s.eventInfoTitle}
          dataHook={DH.subtitleMembers}
          text={guestsTitleText}
          customColor={c.evDescriptionColor}
        />
        <div className={s.members}>
          <Members />
        </div>
      </Container>
      <Container visible={aboutSectionVisible} contentAlignment={contentAlignment} dataHook="aboutSectionContainer">
        <AboutSection aboutTitleText={aboutTitleText} readLessText={readLessText} readMoreText={readMoreText} />
      </Container>
      <Container visible={groupVisible} contentAlignment={contentAlignment} dataHook="groupsContainer">
        <GroupActivity className={s.groupActivity} />
      </Container>
      <Container visible={scheduleVisible} contentAlignment={contentAlignment} dataHook="scheduleContainer">
        <Subtitle
          className={s.eventInfoTitle}
          dataHook={DH.SCHEDULE_SUBTITLE}
          text={scheduleTitleText}
          customColor={c.evDescriptionColor}
        />
        <MobileSchedule t={t} customAlignment={contentAlignment} />
      </Container>
      <Container visible={hasTickets} contentAlignment={contentAlignment} dataHook="ticketPickerContainer">
        <MobileTicketsPicker />
      </Container>
      <SecondaryRsvpButton onClick={() => handleRSVP()} />
      {mapVisible && (
        <div className={s.mapSpacing}>
          <Map />
        </div>
      )}
      <Container visible={socialShareVisible} contentAlignment={contentAlignment} dataHook="socialShareContainer">
        <SocialShareWithTitle page={BI_ORIGINS.EVENT_DETAILS} t={t} />
      </Container>
    </section>
  )
}

const Container = ({visible, contentAlignment, children, dataHook}: ContainerProps) =>
  visible ? (
    <div className={`${s.content} ${getAlignmentClass(contentAlignment)}`} data-hook={dataHook}>
      {children}
    </div>
  ) : (
    <div />
  )

const scrollToTicketsPicker = () => {
  scrollElementIntoView(hookToAttributeSelector(DH.TICKETS_PICKER), {block: 'start', behavior: 'smooth'})
}
