import classNames from 'classnames'
import React from 'react'
import {EventDetailsButtonStyle} from '@wix/wix-events-commons-statics'
import {isFullButton, isHollowButton, isRoundedButton} from '../../../selectors/settings'
import cl from '../../classnames.scss'
import s from './registration-button-skin.scss'
import {RsvpButtonStyleProps} from './index'

const getButtonClasses = (buttonStyle: EventDetailsButtonStyle, secondary: boolean, disabled: boolean) => {
  const classes = [s.registrationButton]

  if (isHollowButton(buttonStyle)) {
    const colorClass = secondary ? cl.secondRsvpHollowButtonColor : cl.rsvpHollowButtonColor
    const borderClass = secondary ? cl.secondRsvpButtonBorder : cl.rsvpButtonBorder
    classes.push(cl.rsvpHollowButtonFont, colorClass, borderClass)
  }

  if (isFullButton(buttonStyle)) {
    const colorClass = secondary ? cl.secondRsvpButtonColor : cl.rsvpButtonColor
    const backgroundClass = secondary ? cl.secondRsvpButtonBackgroundColor : cl.rsvpButtonBackgroundColor
    classes.push(cl.rsvpButtonFont, colorClass, backgroundClass)
  }

  const radiusClass = isRoundedButton(buttonStyle) ? cl.rsvpButtonRoundedBorderRadius : cl.rsvpButtonBorderRadius
  const disabledClass = isRoundedButton(buttonStyle) ? cl.rsvpButtonRoundedDisabled : cl.rsvpButtonDisabled
  classes.push(radiusClass, disabledClass)

  if (disabled) {
    classes.push(s.disabled)
  }

  return classNames(...classes)
}

export const RegistrationButtonSkin = ({
  onClick,
  url,
  isExternalLink,
  text,
  dataHook,
  buttonStyle,
  secondary,
  disabled,
  minWidth,
  minHeight,
  draftEvent,
  narrowerButton,
}: RsvpButtonStyleProps) => {
  const buttonClasses = getButtonClasses(buttonStyle, secondary, disabled)
  const style = {minWidth, minHeight}
  let rel = isExternalLink ? 'nofollow' : ''

  if (draftEvent) {
    rel = 'noreferrer'
  }

  return url && !disabled ? (
    <a
      data-hook={dataHook}
      className={classNames(buttonClasses, s.link, {[s.narrowerButton]: narrowerButton})}
      href={url}
      style={style}
      rel={rel}
      target={draftEvent ? '_blank' : undefined}
    >
      {text}
    </a>
  ) : (
    <button
      data-hook={dataHook}
      className={classNames({[s.narrowerButton]: narrowerButton}, buttonClasses)}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {text}
    </button>
  )
}
