import {
  isRegistrationClosedVisible,
  getEventTicketLimitPerOrder,
  hasSeatingPlan,
  isEnded,
} from '@wix/wix-events-commons-statics'
import {
  getSelectedTicketsQuantity,
  getSelectedTicketQuantity,
  getSelectedTicketDonation,
} from '../../../../selectors/selected-tickets'
import {getButtonStyle, isHollowButton} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {isEventDetailsMobileUpdateEnabled} from '../../../../../../../commons/selectors/experiments'
import {isDonationInputVisible} from '../../../../selectors/tickets-picker'
import {TicketOwnProps, TicketRuntimeProps} from './interfaces'
import {Ticket as MobilePresentation} from './mobile-ticket'
import {Ticket as Presentation} from './ticket'

const mapRuntime = ({state}: DetailsPageAppProps, {ticket: {id}, ticket}: TicketOwnProps): TicketRuntimeProps => {
  const {
    component: {settings},
    event,
  } = state
  const maxTicketsPerReservation = getEventTicketLimitPerOrder(event)
  const selectedQuantity = getSelectedTicketQuantity(state, id)
  const selectedDonation = getSelectedTicketDonation(state, id)
  const availableTicketsQuantity = maxTicketsPerReservation - getSelectedTicketsQuantity(state)

  return {
    selectedQuantity,
    selectedDonation,
    isHollowButton: isHollowButton(getButtonStyle(settings)),
    availableTicketsQuantity,
    registrationClosed: isRegistrationClosedVisible(event),
    withSeatingPlan: hasSeatingPlan(event),
    isEventEnded: isEnded(event),
    donationInputVisible: isDonationInputVisible(state, ticket),
    updatedInterface: isEventDetailsMobileUpdateEnabled(state),
  }
}

export const Ticket = connect<TicketOwnProps, TicketRuntimeProps>(mapRuntime)(Presentation)
export const MobileTicket = connect<TicketOwnProps, TicketRuntimeProps>(mapRuntime)(MobilePresentation)
export * from './interfaces'
