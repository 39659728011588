import {
  isRegistrationClosedVisible,
  getExternalLink,
  hasImage,
  isTicketed,
  isExternal,
  isWithoutRegistration,
  isRegistrationWithCustomDates,
  isRegistrationScheduled,
  saleScheduledAndHasTickets,
} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMemberAbleToFinishRsvp, isMemberRsvpExists} from '../../../selectors/member-rsvp'
import {getButtonStyle, isHeaderRegistrationButtonVisibilityEnabled} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {getHomePageUrl, isMobile} from '../../../../../../commons/selectors/environment'
import {RegistrationButtonOwnProps, RegistrationButtonRuntimeProps} from './interfaces'
import {RegistrationButton as Presentation} from './registration-button'

const mapRuntime = ({state}: DetailsPageAppProps): RegistrationButtonRuntimeProps => {
  const event = state.event
  const componentSettings = state.component.settings
  const ticketedEvent = isTicketed(event)

  return {
    registrationClosed: isRegistrationClosedVisible(event),
    withoutRegistration: isWithoutRegistration(event),
    memberRsvpExists: isMemberRsvpExists(state),
    ticketedEvent,
    componentSettings,
    buttonStyle: getButtonStyle(componentSettings),
    imageVisible: hasImage(event),
    isMobile: isMobile(state),
    ticketsUnavailable: ticketedEvent && (!state.tickets.length || state.tickets.every(saleScheduledAndHasTickets)),
    url: getExternalLink(event),
    isExternalLink: isExternal(event),
    rsvpButtonEnabled: isHeaderRegistrationButtonVisibilityEnabled(state.component.settings),
    registrationScheduled: isRegistrationScheduled(event),
    registrationWithCustomDates: isRegistrationWithCustomDates(event),
    eventId: event.id,
    homePageUrl: getHomePageUrl(state),
    memberAbleToFinishRsvp: isMemberAbleToFinishRsvp(state),
  }
}

export const RegistrationButton = withTranslation()(
  connect<RegistrationButtonOwnProps, RegistrationButtonRuntimeProps>(mapRuntime)(Presentation),
)

export * from './interfaces'
