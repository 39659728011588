import {withTranslation} from '@wix/yoshi-flow-editor'
import {isMobileSquareImage} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {MobileImageOwnProps, MobileImageRuntimeProps} from './interfaces'
import {MobileImage as Presentation} from './mobile-image'

const mapRuntime = ({
  state: {
    component: {settings},
  },
}: DetailsPageAppProps): MobileImageRuntimeProps => ({
  isSquareImage: isMobileSquareImage(settings),
})

export const MobileImage = connect<MobileImageOwnProps, MobileImageRuntimeProps>(mapRuntime)(
  withTranslation()(Presentation),
)

export * from './interfaces'
