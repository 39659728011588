import React, {useState, useEffect} from 'react'
import {TextField} from 'wix-ui-tpa'
import {
  getCurrencySymbol,
  isCurrencySuffixed,
  normalizePrice,
  minimumDonation,
  MAX_PRICE,
} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {DONATION_ERROR} from '../../types'
import {useCurrencyFormatter} from '../../../../../commons/hooks/currency'
import {DonationInputProps} from './interfaces'
import s from './donation-input.scss'

export const DonationInput = ({
  ticket,
  onChange,
  onBlur,
  value,
  error,
  label,
  className,
  ignoreMinimum,
}: DonationInputProps) => {
  const {t} = useTranslation()
  const [localValue, setLocalValue] = useState(value ?? '')

  const currency = ticket.price.currency
  const suffixedCurrency = isCurrencySuffixed(currency)
  const symbol = getCurrencySymbol(currency)
  const minimum = ignoreMinimum ? 0 : minimumDonation(ticket)
  const {getFormattedMoney} = useCurrencyFormatter()

  useEffect(() => {
    if (value === undefined) {
      setLocalValue(value?.toString() ?? '')
    }
  }, [value])

  const handleChange = (val: string) => {
    if (val.length === 0) {
      onChange(undefined)
      setLocalValue('')
    } else {
      const normalizedValue = normalizePrice(val, localValue?.toString(), {max: MAX_PRICE})
      onChange(normalizedValue !== undefined ? normalizedValue : undefined)
      setLocalValue(normalizedValue !== undefined ? normalizedValue : '')
    }
  }

  const getError = () => {
    const ERRORS = {
      [DONATION_ERROR.EMPTY_DONATION]: () => t('tickets.donationError.empty'),
      [DONATION_ERROR.MINIMUM_NOT_REACHED]: () =>
        t('tickets.donationError.minimum', {
          price: getFormattedMoney({amount: minimum?.toString(), currency}),
        }),
    }
    return ERRORS[error]()
  }

  const handleBlur = () => {
    if (value && value.endsWith('.')) {
      handleChange(value.slice(0, value.length - 1))
    }
    onBlur()
  }

  return (
    <div onClick={event => event.stopPropagation()} className={s.donationInputContainer}>
      <TextField
        data-hook={DH.DONATION_INPUT}
        value={localValue ?? ''}
        newErrorMessage
        className={className}
        label={
          label ?? minimum
            ? t('tickets.donationInputLabelWithMinimum', {
                price: getFormattedMoney({amount: minimum.toString(), currency}),
              })
            : t('tickets.donationInputLabel')
        }
        error={Boolean(error)}
        errorMessage={error ? getError() : undefined}
        onChange={e => handleChange(e.target.value)}
        onBlur={handleBlur}
        prefix={suffixedCurrency ? undefined : symbol}
        suffix={suffixedCurrency ? symbol : undefined}
      />
    </div>
  )
}
