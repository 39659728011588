import React from 'react'
import {Footer} from '../../event-details/schedule/footer'
import {List} from '../../event-details/schedule/list'
import {MobileScheduleProps} from '.'

export const MobileSchedule = ({t, items, total, navigateToSchedulePage, customAlignment}: MobileScheduleProps) => (
  <>
    <List t={t} items={items} mobile updatedMobileInterface customAlignment={customAlignment} />
    <Footer total={total} t={t} mobile navigateToSchedulePage={navigateToSchedulePage} updatedMobileInterface />
  </>
)
